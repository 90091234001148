<template>
    <div class="available-wheels-container">
        <div class="custom-tooltip">
            <span class="custom-tooltip-title">{{ theming.locale.wheel.tooltipTitle }}</span>
            <span
                class="wheel-tooltip"
                @click="toggleTooltip"
            >
                <span class="tooltip-base">{{ $t('magicWheel.learnMore') }}</span>
                <div
                    v-if="isTooltipVisible"
                    ref="tooltipBody"
                    class="tooltip-text"
                >
                    <span v-dompurify-html="theming.locale.wheel.tooltipBody" class="tool-tip-body" />
                </div>
            </span>
        </div>
        <div class="wheel-counts">
            <wheel-thumbnail
                v-for="wheel in activeWheels"
                :key="wheel.id"
                :wheel="wheel"
                :standard-wheel-count="standardWheelCount"
                :premium-wheel-count="premiumWheelCount"
                @click.native="emitSelectedWheelType(wheel.type)"
            />
        </div>
    </div>
</template>

<script>
    import rewardWheelGameService from '@/services/reward-wheel-game-service'
    import WheelThumbnail from '@/components/magic-wheel/WheelThumbnail'

    export default {
        components: {
            WheelThumbnail
        },
        props: {
            standardWheelCount: {
                type: Number,
                default: 0
            },
            premiumWheelCount: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                activeWheels: null,
                isTooltipVisible: false
            }
        },
        computed: {
            theming() {
                return this.$store.getters.getTheming
            }
        },
        created() {
            this.fetchActiveWheels()
        },
        methods: {
            fetchActiveWheels() {
                rewardWheelGameService.getActiveWheels().then(response => {
                    this.activeWheels = response.content
                })
            },
            emitSelectedWheelType(type) {
                window.scrollTo(0, 0)
                if (this.standardWheelCount && this.premiumWheelCount) {
                    this.$emit('wheel-type-selected', type)
                }
            },
            toggleTooltip() {
                this.isTooltipVisible = !this.isTooltipVisible
            }
        }
    }
</script>

<style scoped>
.available-wheels-container {
    width: 90%;
    max-width: 300px;
    margin: 0 auto;
}

.custom-tooltip {
    color: var(--ps-white);
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.custom-tooltip-title {
    font-size: 0.8125rem;
    font-weight: 900;
    text-transform: uppercase;
}

.wheel-tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip-base {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: underline;
    font-size: 0.8rem;
}

.tooltip-text {
    width: 250px;
    background-color: #fff;
    color: #000;
    border-radius: 6px;
    padding: 0.5rem;
    line-height: 1.25;
    position: absolute;
    bottom: 148%;
    right: -51px;
    filter: drop-shadow(0 0 5px rgb(0 0 0 / 20%));
    z-index: 2;
}

.tooltip-text::after {
    content: " ";
    position: absolute;
    top: 100%;
    right: 20%;
    border-width: 7px;
    border-style: solid;
    border-color: #fff transparent transparent;
}

.tool-tip-body {
    font-size: 0.8rem;
}

.info-icon {
    width: 0.8rem;
    height: 0.8rem;
    transform: translateY(-0.6rem);
}

.wheel-counts {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
}

@media screen and (min-width: 992px) {
    .wheel-type-count {
        font-size: 2.625rem;
    }

    .tooltip-text {
        width: 350px;
        right: -71px;
    }
}
</style>
