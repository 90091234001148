import restAPI from '@/utils/rest-api'
export default {
    getWheelSessions() {
        return restAPI({
            url: 'reward-wheel-games/available',
            method: 'get'
        })
    },
    getActiveWheels() {
        return restAPI({
            url: 'reward-wheel-games/active',
            method: 'get'
        })
    },
    saveGameSession(info) {
        return restAPI({
            url: 'reward-wheel-games/save',
            method: 'post',
            data: info
        })
    }
}
