<template>
    <div
        v-if="theming"
        class="winner-wheel-banner"
        :style="{backgroundColor: boxBackgroundColor}"
    >

        <div class="icon-image-container">
            <div class="close-button" @click="closePopup">
                <font-awesome-icon icon="fa-solid fa-xmark" />
            </div>
            <img
                class="img-size"
                :src="theming.images.wheelWinnerImage"
                alt="Wheel Image"
            >
        </div>
        <div
            class="box-body"
        >
            <div v-dompurify-html="boxBody" />
            <div v-if="magicWheelResult" class="result-container" :style="{'--result-text-color': theming?.colors?.wheel?.wheelResultTextColor}">
                <div v-if="magicWheelResult?.freeSpins" class="result-part-container">
                    <div class="result-part-wrapper">
                        <font-awesome-icon icon="fa-solid fa-rotate" />
                        <div class="text-container">
                            <span>{{ magicWheelResult.freeSpins }}</span>
                            <span class="result-part-label">{{ $t('magicWheel.freeSpins') }}</span>
                        </div>
                    </div>
                </div>
                <div v-if="magicWheelResult?.goldenChips" class="result-part-container">
                    <div class="result-part-wrapper">
                        <font-awesome-icon icon="fa-solid fa-coins" />
                        <div class="text-container">
                            <span>{{ magicWheelResult.goldenChips }}</span>
                            <span class="result-part-label">{{ $t('magicWheel.goldenChips') }}</span>
                        </div>
                    </div>
                </div>
                <div v-if="magicWheelResult?.freeBets" class="result-part-container">
                    <div class="result-part-wrapper">
                        <font-awesome-icon icon="fa-solid fa-euro-sign" />
                        <div class="text-container">
                            <span>{{ magicWheelResult.freeBets }}</span>
                            <span class="result-part-label">{{ $t('magicWheel.freeBets') }}</span>
                        </div>
                    </div>
                </div>
                <div v-if="!!magicWheelResult?.physicalPrizeAlias" class="result-part-container">
                    <div class="result-part-wrapper physical-prize-wrapper">
                        <font-awesome-icon icon="fa-solid fa-gift" />
                        <div class="text-container">
                            <span>{{ magicWheelResult.physicalPrizeAlias }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            magicWheelResult: {
                type: Object,
                required: true
            }
        },
        computed: {
            theming() {
                return this.$store.getters.getTheming
            },
            boxBody() {
                return this.theming?.locale?.wheel?.winnerText
            },
            boxBackgroundColor() {
                return this.theming?.colors?.wheel?.noWheelBackgroundColor
            }

        },
        methods: {
            closePopup() {
                this.$emit('closePopup')
            }
        }
    }
</script>

<style scoped>
.winner-wheel-banner {
    width: 80%;
    max-width: 450px;
    margin: 0 auto;
    color: var(--dropdown-bg);
    position: absolute;
    left: 50%;
    top: 6rem;
    transform: translateX(-50%);
    border-radius: 8px;
}

.img-size {
    border-radius: 8px 8px 0 0;
    width: 100%;
}

.icon-image-container {
    width: 100%;
    border-radius: 8px 8px 0 0;
    position: relative;
}

.icon-image-container img {
    height: 140px;
    object-fit: cover;
}

.close-button {
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
    width: 30px;
    height: 30px;
    font-size: 2rem;
    display: flex;
    color: #fff;
    cursor: pointer;
    overflow: hidden;
}

.box-body {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.875rem;
    line-height: 1.05rem;
}

.result-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.result-part-container {
    display: flex;
    flex-direction: column;
}

.result-part-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 150px;
    gap: 1rem;
    font-size: 2rem;
    color: var(--result-text-color);
}

.result-part-wrapper.physical-prize-wrapper {
    max-width: unset;
}

.text-container {
    display: flex;
    font-weight: 900;
    gap: 0.5rem;
    font-size: 1.8rem;
}

.result-part-label {
    margin-top: 0.35rem;
    font-size: 0.8rem;
}

.plus-sign {
    font-size: 2rem;
    font-weight: bolder;
    margin: 1.5rem auto;
    color: var(--result-text-color);
}

@media screen and (min-width: 992px) {
    .winner-wheel-banner {
        width: 100%;
        min-height: 400px;
        max-width: 490px;
    }

    .icon-image-container img {
        height: 155px;
    }

    .result-container {
        flex-direction: row;
    }

    .result-part-container {
        flex-direction: row;
    }

    .result-part-label {
        font-size: 1rem;
    }

    .plus-sign {
        margin: 2rem 1.5rem 0;
    }
}

</style>
