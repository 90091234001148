<template>
    <div
        v-if="isLoggedIn"
        class="view-container"
        :class="{'dark-bg': noAvailableWheels || showWinnerPopup}"
    >
        <route-head-row
            :is-nested-route="true"
            :title="routeTitle"
        />
        <magic-wheel-iframe
            v-if="availableWheelsExist"
            :magic-wheel="currentWheel"
            @magic-wheel-finished="magicWheelFinished"
        />
        <winner-wheel-popup v-if="showWinnerPopup" :magic-wheel-result="wheelResult" @closePopup="closePopup" />
        <span
            v-if="availableWheelsExist"
            class="divider-date"
        >
            <span
                class="date"
                :style="{
                    '--date-background': dateBackground || '#000',
                    '--date-text-color': dateTextColor || '#fff'
                }"
            >
                <span>
                    {{ getDayOfWeek(currentWheel.date) }}
                </span>
                <span>
                    {{ formatWheelDate(currentWheel.date) }}
                </span>
            </span>

            <span
                class="divider"
                :style="{
                    '--divider-color': dividerColor || 'transparent'
                }"
            />
        </span>
        <no-wheel-banner v-if="noAvailableWheels && !showWinnerPopup" />
        <available-wheels
            v-if="availableWheelsExist"
            :standard-wheel-count="standardWheelCount"
            :premium-wheel-count="premiumWheelCount"
            @wheel-type-selected="onWheelTypeSelected"
        />
    </div>
</template>

<script>
    import rewardWheelService from '@/services/reward-wheel-game-service'
    import RouteHeadRow from '@/components/RouteHeadRow'
    import MagicWheelIframe from '@/components/magic-wheel/MagicWheelIframe'
    import AvailableWheels from '@/components/magic-wheel/AvailableWheels'
    import NoWheelBanner from '@/components/magic-wheel/NoWheelBanner'
    import moment from 'moment-timezone'
    import WinnerWheelPopup from '@/components/magic-wheel/WinnerWheelPopup'
    export default {
        components: { RouteHeadRow, MagicWheelIframe, NoWheelBanner, AvailableWheels, WinnerWheelPopup },
        data() {
            return {
                availableWheels: null,
                selectedType: null,
                days: [
                    this.$t('magicWheel.sunday'),
                    this.$t('magicWheel.monday'),
                    this.$t('magicWheel.tuesday'),
                    this.$t('magicWheel.wednesday'),
                    this.$t('magicWheel.thursday'),
                    this.$t('magicWheel.friday'),
                    this.$t('magicWheel.saturday')
                ],
                showWinnerPopup: false,
                wheelResult: null
            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.getters.isLoggedIn
            },
            routeTitle() {
                return this.$t('magicWheel.routeTitle')
            },
            noAvailableWheels() {
                return this.availableWheels && this.availableWheels.length === 0
            },
            availableWheelsExist() {
                return this.availableWheels && this.availableWheels.length > 0
            },
            currentWheel() {
                return this.availableWheels[0]
            },
            standardWheelCount() {
                return this.availableWheels?.filter(wheel => wheel.type === 'STANDARD').length
            },
            premiumWheelCount() {
                return this.availableWheels?.filter(wheel => wheel.type === 'PREMIUM').length
            },
            theming() {
                return this.$store.getters.getTheming
            },
            dateBackground() {
                return this.theming?.colors?.wheel?.dateBackground
            },
            dateTextColor() {
                return this.theming?.colors?.wheel?.dateTextColor
            },
            dividerColor() {
                return this.theming?.colors?.wheel?.dividerColor
            }
        },
        watch: {
            selectedType(newValue) {
                if (newValue) {
                    this.availableWheels.sort((a, b) => {
                        return a.type === newValue ? -1 : 1
                    })
                }
            },
            isLoggedIn(newValue) {
                if (newValue) {
                    this.fetchAvailableWheels()
                }
            }
        },
        mounted() {
            this.fetchAvailableWheels()
        },
        methods: {
            fetchAvailableWheels() {
                this.availableWheels = []
                this.availableWheels = [...this.availableWheels]
                rewardWheelService.getWheelSessions().then(response => {
                    this.availableWheels = response.content
                }).catch((err) => {
                    if (err.response.data.code === 'NO_AVAILABLE_REWARD_WHEEL_FOR_PLAYER') {
                        this.availableWheels = []
                    }
                })
            },
            getDayOfWeek(date) {
                return this.days[moment.utc(date).toDate().getDay()]
            },
            formatWheelDate(date) {
                return moment.utc(date).format('DD/MM')
            },
            onWheelTypeSelected(type) {
                this.selectedType = type
            },
            magicWheelFinished(result) {
                this.showWinnerPopup = true
                this.wheelResult = result
                this.fetchAvailableWheels()
            },
            closePopup() {
                this.showWinnerPopup = false
            }
        }
    }
</script>

<style scoped>
.view-container {
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.dark-bg {
    background-color: rgba(0, 0, 0, 0.4);
    height: 100%;
    min-height: 100vh;
}

.divider-date {
    margin: 1rem auto;
}

.divider-date .date {
    padding: 0 1rem;
    border-radius: 50px;
    height: 40px;
    color: var(--date-text-color);
    background: var(--date-background);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
}

.divider {
    display: block;
    width: 235px;
    height: 1px;
    background-color: var(--divider-color);
    margin: 1rem auto 0;
}

@media screen and (min-width: 992px) {
    .view-container {
        padding-bottom: 5rem;
    }

    .divider {
        width: 460px;
        margin: 1.5rem auto 0.5rem;
    }
}
</style>
