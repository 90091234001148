<template>
    <div
        class="wheel-type"
    >
        <div class="wheel-thumbnail-container">
            <img
                class="wheel-thumbnail"
                :src="wheel.thumbnailImage"
                alt=""
            >
            <div class="wheel-type-count">
                {{ wheelCount(wheel.type) }}
            </div>
        </div>
        <span class="wheel-type-text">
            {{ wheelType(wheel.type) }}
            <br>
            {{ $t('magicWheel.wheel') }}
        </span>

    </div>
</template>

<script>
    export default {
        props: {
            wheel: {
                type: Object,
                required: true
            },
            standardWheelCount: {
                type: Number,
                default: 0
            },
            premiumWheelCount: {
                type: Number,
                default: 0
            }

        },
        methods: {
            wheelType(type) {
                return type === 'STANDARD' ? this.$t('magicWheel.standard') : this.$t('magicWheel.premium')
            },
            wheelCount(type) {
                return type === 'STANDARD' ? this.standardWheelCount : this.premiumWheelCount
            }
        }
    }
</script>

<style scoped>

.wheel-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.wheel-thumbnail-container {
    position: relative;
}

.wheel-type-count {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    font-size: 1.5625rem;
    line-height: 1;
    font-weight: 800;
    color: var(--ps-white);
}

.wheel-type-text {
    font-size: 0.8125rem;
    font-weight: 900;
    color: var(--default-text-color);
    text-transform: uppercase;
    width: 100%;
    text-align: center;
}

@media screen and (min-width: 992px) {
    .wheel-type-count {
        font-size: 2.625rem;
    }
}
</style>
