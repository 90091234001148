<template>
    <div class="outer-container">
        <div
            class="magic-wheel-container"
        >
            <div
                class="iframe-wrapper"
                @click.self="spinWheel"
            >
                <magic-wheel-loader v-if="!iframeLoaded" />
                <transition name="fade">
                    <iframe
                        v-show="iframeLoaded"
                        id="magic-wheel-game"
                        ref="magicWheelIframe"
                        :src="iframeSrc"
                        frameborder="0"
                        class="iframe-content"
                        allow="autoplay"
                    />
                </transition>
            </div>
        </div>

    </div>
</template>

<script>
    import rewardWheelGameService from '@/services/reward-wheel-game-service'
    import MagicWheelLoader from '@/components/magic-wheel/MagicWheelLoader'
    import config from '@/mixins/config'

    export default {
        components: { MagicWheelLoader },
        mixins: [config],
        props: {
            magicWheel: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                selectedMagicWheelGame: null,
                iframeLoaded: false,
                spinTriggered: false,
                locationPath: window.location.protocol + '//' + window.location.host,
                magicWheelResult: null,
                sound: null
            }
        },
        computed: {
            iframeSrc() {
                if (this.magicWheel) {
                    return this.config.gameServerHost + '/' + this.magicWheel.id + '?targetOrigin=' + this.locationPath + '&accessToken=' + this.accessToken
                } else {
                    return null
                }
            },
            iframeElement() {
                return document.getElementById('magic-wheel-game')
            },
            accessToken() {
                return this.$store.getters.getJwtToken
            },
            getTheming() {
                return this.$store.getters.getTheming
            }
        },
        watch: {
            magicWheel: {
                handler(newVal, oldVal) {
                    if (newVal.type !== oldVal.type || newVal.date !== oldVal.date) {
                        this.iframeLoaded = false
                        this.spinTriggered = false
                    }
                    this.selectedMagicWheelGame = newVal
                }
            }
        },
        created() {
            window.addEventListener('message', this.handler)
            this.selectedMagicWheelGame = this.magicWheel
        },
        beforeDestroy() {
            window.removeEventListener('message', this.handler)
        },
        methods: {
            spinWheel() {
                if (this.spinTriggered || !this.iframeLoaded) {
                    return
                }
                this.spinTriggered = true
                const magicWheelInfo = {
                    rewardWheelGameId: this.selectedMagicWheelGame.id,
                    campaignId: this.selectedMagicWheelGame.campaignId,
                    date: this.selectedMagicWheelGame.date
                }
                rewardWheelGameService.saveGameSession(magicWheelInfo).then((res) => {
                    this.iframeElement.contentWindow.postMessage({ action: 'lucky-wheel-spin', prizeId: res.content.prizeId }, this.config.gameServerHost)
                    this.magicWheelResult = res.content
                    if (this.getTheming?.sounds?.wheel) {
                        this.sound = new Audio(this.getTheming.sounds.wheel)
                        this.sound.play()
                    }
                })
                // const playerId = sessionStorage.getItem('loggedInPlayerId')
                // this.$gtm.trackEvent({
                //     event: 'SPIN_WHEEL',
                //     eventCategory: 'WHEEL',
                //     eventAction: 'SPIN',
                //     eventLabel: this.selectedMagicWheelGame.locale.name,
                //     eventValue: playerId
                // })
            },
            handler(event) {
                if (!event.data) {
                    return
                }
                if (`${event.origin}/opap/casino` !== this.config.gameServerHost) {
                    return
                }
                if (event.data.action === 'load-completed') {
                    this.iframeLoaded = true
                }
                if (event.data.action === 'finish') {
                    if (this.sound) {
                        this.sound.pause()
                    }
                    setTimeout(() => {
                        this.$emit('magic-wheel-finished', this.magicWheelResult)
                    }, 1500)
                    this.magicWheelResultPoints = event.data.score
                    // if (this.getTheming?.sounds?.luckyWheelPopUpSound) {
                    //     this.sound.src = this.getTheming.sounds.luckyWheelPopUpSound
                    //     this.sound.play()
                    // }
                }
            }
        }
    }
</script>

<style scoped>
.fade-enter-active {
    transition-duration: 0.5s;
}

.fade-leave-active {
    transition-duration: 0s;
}

.outer-container {
    max-width: 1286px;
    padding-top: 0.5rem;
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 0 auto;
}

.magic-wheel-container {
    display: grid;
    place-items: center;
    width: 100%;
    transform: translate3d(0, 0, 0);
}

.iframe-wrapper {
    position: relative;
    width: 100%;
    max-width: 650px;
    cursor: pointer;
    margin: 0 auto;
    display: grid;
    place-items: center;
}

.iframe-content {
    position: relative;
    width: 100%;
    max-width: 650px;
    min-height: 55vh;
}

.iframe-wrapper::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}

@media screen and (min-width: 768px) {
    .iframe-wrapper {
        min-height: 55vh;
    }
}

@media screen and (min-width: 992px) {
    .iframe-wrapper {
        position: relative;
        width: 100%;
        max-width: 800px;
        min-height: 600px;
        cursor: pointer;
    }

    .iframe-content {
        width: 100%;
        min-height: 600px;
    }
}
</style>
