<template>
    <div
        v-if="theming"
        class="no-wheel-banner box-container"
        :style="{
            backgroundColor: boxBackgroundColor
        }"
    >
        <div class="icon-image-container">
            <img
                class="img-size"
                :src="theming.images.wheelWinnerImage"
                alt="Wheel Image"
            >
        </div>
        <div
            class="box-body"
        >
            <div v-dompurify-html="boxBody" />
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            theming() {
                return this.$store.getters.getTheming
            },
            boxBody() {
                return this.theming?.locale?.wheel?.noWheelText
            },
            boxBackgroundColor() {
                return this.theming?.colors?.wheel?.noWheelBackgroundColor
            }

        }
    }
</script>

<style scoped>
.box-container {
    width: 80%;
    min-height: 450px;
    max-width: 450px;
    margin: 0 auto;
    color: var(--dropdown-bg);
    border-radius: 8px;
}

.img-size {
    border-radius: 8px 8px 0 0;
    width: 100%;
}

.icon-image-container {
    width: 100%;
    border-radius: 8px 8px 0 0;
    position: relative;
}

.icon-image-container img {
    height: 140px;
    object-fit: cover;
}

.box-body {
    padding: 1rem;
    font-size: 0.875rem;
    line-height: 1.05rem;
}

@media screen and (min-width: 992px) {
    .box-container {
        width: 100%;
        min-height: 400px;
    }
}

</style>
